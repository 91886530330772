<template>
  <div class="order_detail_wrapper">
    <div class="header">
      <div>
        <a-button icon="arrow-left" size="small" @click="back" />
        <span class="page_type">详情</span>
      </div>
    </div>
    <div class="content">
      <div class="common flex-row">
        <div class="left">
          <div class="basic-info">
            <div class="img-wrapper">
              <a-avatar shape="square" :size="26" :src="titlePrefix" />
            </div>
            <div>
              <div class="code">加盟销售订单号：{{ info.code }}</div>
              <div class="customer">{{ info.customerHead }} <span :class="`status-${info.status}`">{{
                EXECUTION_STATUS_ARRAY_MAP[info.status] }}</span>
              </div>
            </div>
          </div>
          <a-tabs default-active-key="1" @change="handleActiveChange">
            <a-tab-pane key="1" tab="订单信息">
            </a-tab-pane>
            <a-tab-pane key="2" tab="日志动态">
            </a-tab-pane>
            <a-tab-pane key="3" tab="关联审批">
            </a-tab-pane>
            <a-tab-pane key="4" tab="收款信息">
            </a-tab-pane>
            <a-tab-pane key="5" tab="开票信息">
            </a-tab-pane>
            <a-tab-pane key="7" tab="关联合同">
            </a-tab-pane>
            <a-tab-pane key="8" tab="关联订单">
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <template v-if="activeKey === '1'">
        <div class="common">
          <div class="title gap-12 ">基本信息</div>
          <a-descriptions>
            <a-descriptions-item label="销售模式">
              {{ contractInfo.saleModelDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="业务类型">
              {{ contractInfo.businessTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="客户抬头">
              {{ info.customerHead }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商抬头">
              {{ info.accountingUnit }}
            </a-descriptions-item>
            <a-descriptions-item label="最终用户">
              {{ contractInfo.finalCustomerName }}
            </a-descriptions-item>
            <a-descriptions-item label="发票类型">
              {{ contractInfo.invoiceTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="收款账号" :span="3">
              {{ `${contractInfo.bankOfDeposit}_${contractInfo.accounts}` }}
            </a-descriptions-item>
            <a-descriptions-item label="结算方式">
              {{ contractInfo.paymentMethodDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="关联合同">
              {{ info.contractCode }}
            </a-descriptions-item>
            <a-descriptions-item label="提货方式">
              {{ DELIVERY_METHOD_MAP[info.deliveryMethod] }}
            </a-descriptions-item>
            <a-descriptions-item label="是否含运">
              {{ info.isIncludedFare ? '含运' : '不含运' }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ info.createUserName }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ formatDate(info.createTime, 'YYYY-MM-DD HH:mm:ss') }}
            </a-descriptions-item>
            <a-descriptions-item label="订单备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="common">
          <div class="title gap-12 ">开单明细</div>
          <xz-table :columns="deviceColumns" :data="info.goodsList" rowKey="id" :bordered="true">
          </xz-table>
        </div>
      </template>
      <template v-if="activeKey === '2'">
        <log :id="info.id" :remindType="2"></log>
      </template>
      <template v-if="activeKey === '3'">
        <AuditList :id="info.id"></AuditList>
      </template>
      <template v-if="activeKey === '4'">
        <a-tabs default-active-key="plan" style="background-color: #fff;">
          <a-tab-pane key="plan" tab="收款计划" style="padding: 0 16px 16px;">
            <xz-table :columns="planColumns" :data="info.paymentPlanList" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'">
            </xz-table>
          </a-tab-pane>
          <a-tab-pane key="actual" tab="实付信息" style="padding: 0 16px 16px;">
            <xz-table :columns="actualColumns" :data="flows" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'"></xz-table>
          </a-tab-pane>
        </a-tabs>
      </template>
      <template v-if="activeKey === '5'">
        <div style="background-color: #fff; padding: 0 16px 16px;">
          <xz-table :columns="invoiceColumns" :data="invoiceList" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'"></xz-table>
        </div>
      </template>
      <template v-if="activeKey === '7'">
        <div style="background-color: #fff;">
          <div class="extra-input"
            style="padding: 16px 16px 0; font-weight: bold; display: flex; justify-content: space-between;">
            合同号： {{ contractInfo.code }}
            <div style="flex: 1; text-align: right;">
              <span style="margin-right: 2em;">合同主题： {{ contractInfo.name }}</span>
            </div>
          </div>
          <div style="padding: 0 16px 16px">
            <xz-table :columns="goodsColumns" :data="contractInfo.deviceList" :scroll="{ x: '100%', y: 540 }"
              :rowKey="'id'"></xz-table>
          </div>
        </div>
      </template>
      <template v-if="activeKey === '8'">
        <div style="background-color: #fff; padding: 0 16px 16px;">
          <orders :code="info.purchaseOrderCode" :saleOrderType="'purchaseOrder'"></orders>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { saleOrderDetail, saleContractDetail, saleCapitalFlow, invoiceList } from '@/api/contract';
import * as Moment from 'dayjs';
import Log from '../saleContracts/components/Detail/Log.vue';
import AuditList from '../saleContracts/components/Detail/AuditList.vue';
import Orders from './components/Orders.vue';

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已取消', '已完结'];
const DELIVERY_METHOD_MAP = ['自提', '商家发货', '不限'];

export default {
  name: 'SaleContractDetail',
  components: {
    xzTable,
    Log,
    AuditList,
    Orders,
  },
  computed: {
    goodsColumns () {
      let columns = [];

      if (this.contractInfo.businessType === 'MAINTENANCE') {
        columns = [
          {
            title: '合同行',
            key: 'index',
            width: 160,
            ellipsis: true,
            customRender: (text, record, index) => `${index + 1}`,
          },
          {
            title: '设备信息',
            key: 'desc',
            ellipsis: true,
            width: 160,
            customRender: (text, record) => {
              return `${record.deviceName || '-'}/${record.deviceModel || '-'}/${record.deviceBrandName || '-'}/${record.deviceSerialNumber || '-'}`;
            },
          },
          {
            title: '保养起始日',
            dataIndex: 'serviceTermStart',
            key: 'serviceTermStart',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return this.formatDate(text);
            },
          },
          {
            title: '保养终止日',
            dataIndex: 'serviceTermEnd',
            key: 'serviceTermEnd',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return this.formatDate(text);
            },
          },
          {
            title: '保养次数',
            dataIndex: 'maintenanceTimes',
            key: 'maintenanceTimes',
            width: 160,
            ellipsis: true,
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? parseInt(text * 100) + '%' : '';
            },
          },
          {
            title: '含税金额（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 160,
            ellipsis: true,
          },
        ];
      } else if (this.contractInfo.businessType === 'WARRANTY') {
        columns = [
          {
            title: '合同行',
            key: 'index',
            width: 160,
            ellipsis: true,
            customRender: (text, record, index) => `${index + 1}`,
          },
          {
            title: '设备信息',
            key: 'desc',
            width: 160,
            ellipsis: true,
            customRender: (text, record) => {
              return `${record.deviceName || '-'}/${record.deviceModel || '-'}/${record.deviceBrandName || '-'}/${record.deviceSerialNumber || '-'}`;
            },
          },
          {
            title: '服务起始日',
            dataIndex: 'serviceTermStart',
            key: 'serviceTermStart',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return this.formatDate(text);
            },
          },
          {
            title: '服务终止日',
            dataIndex: 'serviceTermEnd',
            key: 'serviceTermEnd',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return this.formatDate(text);
            },
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? parseInt(text * 100) + '%' : '';
            },
          },
          {
            title: '含税金额（元）',
            dataIndex: 'taxAmount',
            width: 160,
            key: 'taxAmount',
            ellipsis: true,
          },
        ];
      } else if (this.contractInfo.businessType === 'SINGLE_REPAIR') {
        columns = [
          {
            title: '合同行',
            key: 'index',
            width: 160,
            ellipsis: true,
            customRender: (text, record, index) => `${index + 1}`,
          },
          {
            title: '设备信息',
            key: 'desc',
            width: 400,
            ellipsis: true,
            customRender: (text, record) => {
              return `${record.deviceName || '-'}/${record.deviceModel || '-'}/${record.deviceBrandName || '-'}/${record.deviceSerialNumber || '-'}`;
            },
          },
          {
            title: '故障描述',
            dataIndex: 'faultDescription',
            key: 'faultDescription',
            width: 160,
            ellipsis: true,
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
          },
          {
            title: '含税金额（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 160,
            ellipsis: true,
          },
        ];
      } else if (this.contractInfo.businessType === 'TECHNOLOGY_SERVICE') {
        columns = [
          {
            title: '合同行',
            key: 'index',
            width: 160,
            ellipsis: true,
            customRender: (text, record, index) => `${index + 1}`,
          },
          {
            title: '设备信息',
            key: 'desc',
            width: 160,
            ellipsis: true,
            customRender: (text, record) => {
              return `${record.deviceName || '-'}/${record.deviceModel || '-'}/${record.deviceBrandName || '-'}/${record.deviceSerialNumber || '-'}`;
            },
          },
          {
            title: '税率',
            dataIndex: 'taxRate',
            key: 'taxRate',
            width: 160,
            ellipsis: true,
            customRender: (text) => {
              return text ? parseInt(text * 100) + '%' : '';
            },
          },
          {
            title: '含税金额（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 160,
            ellipsis: true,
          },
        ];
      }

      return columns;
    },
  },
  data () {
    return {
      deviceColumns: [
        {
          title: '履约标的',
          children: [
            {
              title: '合同行',
              key: 'index',
              ellipsis: true,
              customRender: (text, record, index) => `${index + 1}`,
            },
            {
              title: '履约标的',
              dataIndex: 'description',
              key: 'description',
              ellipsis: true,
            },
          ],
        },
        {
          title: '结算明细',
          children: [
            {
              title: '商品名称',
              dataIndex: 'goodsName',
              key: 'goodsName',
              ellipsis: true,
            },
            {
              title: '规格',
              dataIndex: 'specification',
              key: 'specification',
              ellipsis: true,
            },
            {
              title: '型号',
              dataIndex: 'model',
              key: 'model',
              ellipsis: true,
            },
            {
              title: '数量',
              dataIndex: 'num',
              key: 'num',
              ellipsis: true,
              scopedSlots: { customRender: 'num' },
            },
            {
              title: '单位',
              dataIndex: 'unitName',
              key: 'unitName',
              ellipsis: true,
            },
            {
              title: '单价',
              dataIndex: 'taxPrice',
              key: 'taxPrice',
              ellipsis: true,
              scopedSlots: { customRender: 'taxPrice' },
            },
            {
              title: '税率',
              dataIndex: 'taxRate',
              key: 'taxRate',
              ellipsis: true,
              customRender: (text) => {
                return text ? parseInt(text * 100) + '%' : '';
              },
            },
            {
              title: '金额',
              key: 'amount',
              ellipsis: true,
              customRender: (text, record) => {
                return record.num && record.taxPrice ? (record.num * record.taxPrice).toLocaleString() : '';
              },
            },
          ],
        },
      ],
      planColumns: [
        {
          title: '结算类项目',
          dataIndex: 'name',
          key: 'name',
          width: 160,
          ellipsis: true,
        },
        {
          title: '期数/次数',
          dataIndex: 'periodsNumber',
          key: 'periodsNumber',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款日期',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return this.formatDate(text);
          },
        },
        {
          title: '收款金额',
          dataIndex: 'paymentAmount',
          key: 'paymentAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 160,
          ellipsis: true,
        },
      ],
      actualColumns: [
        {
          title: '收款日期',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款金额',
          dataIndex: 'amount',
          key: 'amount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '支付方式',
          dataIndex: 'paymentTypeDesc',
          key: 'paymentTypeDesc',
          width: 160,
          ellipsis: true,
        },
        {
          title: '流水号',
          dataIndex: 'code',
          key: 'code',
          width: 160,
          ellipsis: true,
        },
      ],
      invoiceColumns: [
        {
          title: '发票类型',
          dataIndex: 'invoiceTypeStr',
          key: 'invoiceTypeStr',
          width: 160,
          ellipsis: true,
        },
        {
          title: '发票号码',
          dataIndex: 'invoiceCode',
          key: 'invoiceCode',
          width: 160,
          ellipsis: true,
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          width: 160,
          ellipsis: true,
        },
        {
          title: '规格型号',
          dataIndex: 'spec',
          key: 'spec',
          width: 160,
          ellipsis: true,
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          key: 'brandName',
          width: 160,
          ellipsis: true,
        },
        {
          title: '无税金额（元）',
          dataIndex: 'pureAmount',
          key: 'pureAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '税额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '价税合计（元）',
          dataIndex: 'totalAmount',
          key: 'totalAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '开票日期',
          dataIndex: 'invoiceDate',
          key: 'invoiceDate',
          width: 160,
          ellipsis: true,
        },
        {
          title: '发票状态',
          dataIndex: 'businessStatusStr',
          key: 'businessStatusStr',
          width: 160,
          ellipsis: true,
        },
      ],
      titlePrefix: require('../../../assets/title_prefix.svg'),
      activeKey: '1',
      info: {},
      EXECUTION_STATUS_ARRAY_MAP,
      DELIVERY_METHOD_MAP,
      flows: [],
      invoiceList: [],
      purchaseInfo: {},
      contractInfo: {},
    };
  },
  methods: {
    back () {
      this.$router.back();
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    async getDetail () {
      const { body } = await saleOrderDetail({
        id: this.$decode(this.$route.query.id),
        findDeviceFlag: true,
      });

      this.info = body || {};

      if (this.info.contractId) {
        this.getContractDetail(this.info.contractId);
      }
    },
    async getContractDetail (id) {
      const { body } = await saleContractDetail({
        id,
        findDeviceFlag: true,
      });

      this.contractInfo = body || {};

      let skus = {};
      (body?.deviceList || []).forEach(sku => {
        skus[sku.deviceId] = sku;
      });

      (this.info.goodsList || []).forEach(v => {
        const sku = skus[v.model];

        if (sku) {
          v.desc = `${sku.deviceName || '-'}_${sku.deviceModel || '-'}_${sku.deviceBrandName || '-'}/${sku.deviceSerialNumber || '-'}`;
        }
      });
    },
    async getFlow () {
      const { body } = await saleCapitalFlow({
        saleContractId: this.info.contractId,
        pageNum: 1,
        pageSize: 999,
      });

      this.flows = body?.list || [];
    },
    async getInvoiceList () {
      const { body } = await invoiceList({
        id: this.info.id,
        idType: 1,
        type: 1,
      });

      this.invoiceList = body || [];
    },
    handleActiveChange (v) {
      this.activeKey = v;

      if (this.activeKey === '4') {
        this.getFlow();
      } else if (this.activeKey === '5') {
        this.getInvoiceList();
      }
    },
  },
  created () {
    this.getDetail();
  },
};
</script>

<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}

.order_detail_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 52px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .page_type {
      font-size: 16px;
      font-weight: bold;
      margin-left: 1em;
    }
  }

  .content {
    flex: 1;
    padding: 16px 0 0;
    overflow: auto;

    .gap-12 {
      margin-bottom: 12px;
    }

    .common {
      background-color: #fff;
      padding: 12px;
      margin-bottom: 6px;

      &.flex-row {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-bottom: 0;

        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .basic-info {
            display: flex;
            align-items: center;

            .img-wrapper {
              width: 40px;
              height: 40px;
              background-color: #E2F3FE;
              border-radius: 4px;
              margin-right: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .code {
              font-size: 14px;
              color: #929AA6;
            }

            .customer {
              font-size: 16px;
              font-weight: 600;

              .status-0,
              .status-5 {
                @include tag_round();
                color: #1F2733;
                background-color: #F2F4F7;
              }

              .status-1,
              .status-3 {
                @include tag_round();
                background-color: #E2F3FE;
                color: #237FFA;
              }

              .status-4 {
                @include tag_round();
                background-color: #E5FEEB;
                color: #14CA64;
              }

              .status-2 {
                @include tag_round();
                background-color: #FEE9E5;
                color: #FF5959;
              }
            }
          }

          /deep/ .ant-tabs-bar {
            margin: 0;

            .ant-tabs-nav {
              .ant-tabs-tab {
                margin-right: 16px;
              }
            }
          }
        }
      }

      .title {
        font-weight: bold;
      }
    }
  }

  .extra-input {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 12px 0;
    background: #fff;

    span {
      width: 9em;
      flex-shrink: 0;
    }
  }
}
</style>

